import { memo, useCallback, useEffect, useState } from "react";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProductId,
  setProductId,
} from "../../store/private/homePageSlice";
import ProductCard from "./productCard";
import { CardContainer } from "./styled/cardContainer.styled";
import CustomSlider from "../../shared/partnerComponents/slider";
import { useWindowSize } from "../../shared/utils/mixedControl";
import { OurMachinesStyled } from "./styled/ourMachines.styled";
import { getLanguageID } from "../../shared/utils/common";
import { homePageModel } from "../../shared/models/mainComponents/homePageModel";
import { getCookiesClient } from "../../shared/utils/cookie/cookiesClient";

const HomeTabCard = ({ productCategories, products, widgets }) => {
  const breakpoint = useWindowSize().width > 959 ? true : false;
  const selectedID = useSelector(selectProductId);
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const [ourMachines, setOurMachines] = useState(null);
  const [newProduct, setProduct] = useState([]);
  const settings = {
    centerMode: true,
    infinite: !breakpoint,
    slidesToShow: !breakpoint ? 1 : 3,
    speed: 1500,
    variableWidth: true,
    adaptiveHeight: true,
  };

  useEffect(() => {
    const ourMachines = widgets?.find((item) => item.code === "ourMachines");
    const { title, subTitle } = ourMachines || {};
    setOurMachines({
      title,
      subTitle,
    });
  }, [widgets, selectedID, newProduct]);

  
  //tab değiştiği zaman statein güncellenmesi gerekiyordu ama bu ssr tarafında güncellenmediği için mecburen handletab çalıştığında yeniden
  // çekilmesi gerekti, ayrıca sadece getpage isteğini atarsak eğer invalid token aldığımız için önce auth burada çalışmadığı için fetch ile istek attık
  const handleTab = useCallback(async (item) => {
    try {
      dispatch(setProductId(item || 1));
      // `getPageRender` işlevinin çağrıldığından ve gerekli verileri aldığından emin olun
      await getPageRender(item);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [dispatch]);


  const getPageRender = async(item) => {
    const token = decodeURIComponent(getCookiesClient("token"));
    const ctx = {
      userSettings: {
        languageID: getLanguageID(),
      },
    };
    let apiHost = "https://erseykurumsalrouter.kartega.com/api/";
   
    await fetch(`${apiHost}content/GetPage`, {
      method: 'POST',
      headers:{
        ApplicationKey: "Site",
        AppKey:
          "667f080495499039da1cedabd69a78c3550cd151fbf759a1402df259553a572c04b91249f3cb5ca66f942b4a305e018494cfaead0d4bb65e1029ce3c2919386e",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
          
      },
      body: JSON.stringify({
        data: {
          code: 'homePage',
          productCategoryID: item,
        },
      }),
    }).then((response) => response.json())
      .then((data) => {
        if (!data.hasErrors) {
          const newData = homePageModel(data.data, ctx.userSettings.languageID);
          setProduct(newData.products);
        }
      });
  }

  return (
    <OurMachinesStyled className="ourMachines">
      <div className="ourMachines-container">
        <div className="ourMachines-container-content">
          <div className="ourMachines-container-content__title newTitle">
            {ourMachines?.title}
          </div>
          <div className="ourMachines-container-content__subTitle newSubTitle">
            {ourMachines?.subTitle}
          </div>
        </div>
      </div> 
      <Tabs
        defaultActiveKey={selectedID || "1"}
        centered
        onChange={(key) => handleTab(key)}
      >
        {productCategories?.map((item, i) => {
          return(
            <TabPane tab={item.name} key={item.id}>
              {
                <CardContainer className="cardContainer">
                  <div className="cardContainer-cards">
                    <CustomSlider
                      arrowsBottomActive={true}
                      newSettings={settings}
                      countControl={breakpoint && newProduct.length > 0 ? newProduct.length : products.length}
                    >
                      {(newProduct.length > 0 ? newProduct : products)?.map((product, index) => (
                        <div className="introduction-item" key={index}>
                          <ProductCard product={product} index={index} /> 
                        </div>
                      ))}
                    </CustomSlider>
                  </div>
                </CardContainer>
              }
            </TabPane>
          )
        })}
      </Tabs>
    </OurMachinesStyled>
  );
};
export default memo(HomeTabCard);
