import styled from "styled-components";
import settingsStyled from "../../../styled/settings.styled";

export const HomeImagesStyled = styled.section`
  width: 100%;
  margin-bottom: 145px;

  @media ${settingsStyled.m1200} {
    margin-bottom: 115px;
  }

  @media ${settingsStyled.m959} {
    margin-bottom: 87px;
  }

  @media ${settingsStyled.m767} {
    margin-bottom: 67px;
  }

  .homeImages {
    &-image {
      &-container {
        &-text {
          position: relative;
          p {
            position: absolute;
            z-index: 2;
            top: 8.778vw;
            left: 12.167vw;
            font-size: 2.111vw;
            width: 38%;
            color: #fff;
            text-shadow: 0px 2px 4px #537dee;
            //font-size: 38px;
            font-style: normal;
            line-height: normal;

            @media ${settingsStyled.m479} {
              //font-size: 14px;
              line-height: normal;
              max-height: 110px;
              font-size: 3.111vw;
              width: 45%;
              top: 12.778vw;
              left: 20px;
            }
          }
        }
        height: 660px;
        @media ${settingsStyled.m1279} {
          height: 358px !important;
        }
        @media ${settingsStyled.m959} {
          height: 238px !important;
        }
      position: relative;
        &-fullWidth {
          z-index: 1;
          position: relative;
          width: 100% !important;
          aspect-ratio: auto 100 / 660;
          @media ${settingsStyled.m1279} {
            width: 100% !important;
            height: 100% !important;

          }
          @media ${settingsStyled.m959} {
            width: 100% !important;
            height: 100% !important;

          }
        }
        &-pngKatman {
          position: absolute;
          width: 100% !important;
          height: 660px !important;
          top: 0;
          left: 0;
          z-index: 1;
          @media ${settingsStyled.m1279} {
            width: 100% !important;
            height: 100% !important;

          }
          @media ${settingsStyled.m959} {
            width: 100% !important;
            height: 100% !important;

          }
        }
        &-right {
          &-aligned {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
            width: 710px !important;
            height: auto !important;

            @media ${settingsStyled.m1920} {
              width: 40vw !important;
            }

            @media ${settingsStyled.m959} {
              right: -40px;
              width: 236px !important;
              height: 265px !important;
            }
          }
        }
      }
    }
  }
`;
