import styled from "styled-components";
import settingsStyled from "../../../styled/settings.styled";

const CardContainer = styled.div`
  justify-content: center;
  padding: 10px;
  .placeholder {
    width: 100%;
    height: 200px; /* Örneğin, yüklenen resmin yüksekliği */
    background-color: #f0f0f0;
  }
  .cardContainer {
    /*
    .ant-card .ant-card-bordered{
      
      width: 422px;
        height: 548px;
    }* */
    &-buttons {
      display: flex;
      flex-direction: row; /* Set the direction to column */
      align-items: center;
      justify-content: center; /* Align buttons to the center */
      margin-top: 69px;
      gap: 80px;
    }

    &-cards {
      display: flex;
      flex-direction: row;
      .slick-track {
        display: flex;
        flex-direction: row;
      }
      .ant-card-body {
        padding: 0;
        height: 100%;
      }
      .introduction-item {
        height: 555px;

        @media ${settingsStyled.m959} {
          height: 500px;
        }

        @media ${settingsStyled.m479} {
          height: 315px;
        }
      }
      &-card {
        width: 422px;
        height: 549px;
        margin-right: 40px;
        border-radius: 10px;
        @media ${settingsStyled.m479} {
          width: 276px;
          height: 311px;
          margin-right: 24px;
          border-radius: 8px;
        }
        box-shadow: 0px 4px 10.47px 2px rgba(103, 103, 103, 0.1);
        .ant-image {
          img {
            height: 100%;
            width: 100%;
          }
          @media ${settingsStyled.m479} {
            height: 153px;
            width: 100%;
          }
          height: 262px;
        }
      }
      &-content {
        height: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding: 20px;
        &-button {
          button {
            display: flex;
            flex-direction: row;
            border-color: transparent;
            align-items: center;
            .formSubmitButton--iconRight {
              margin-left: 0px;
            }
            padding-left: 0px;
            & > div > svg {
              fill: #365097;
              width: 24px;
              height: 24px;
            }
          }
          span {
            color: var(--Primary-Blue, #1c3b84);
            font-family: Inter;
            font-display: swap;
            font-size: 16px;
            font-style: normal;
            font-weight: 600; /* 32.005px */
            text-align: center;
          }
        }
        &-title {
          color: var(--bw-black, #343434);
          font-family: Inter;
          font-display: swap;
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 56px */

          @media ${settingsStyled.m479} {
            font-size: 16px;
            line-height: normal;
          }
        }
        &-description {
          overflow: hidden;
          color: #5d7285;
          text-overflow: ellipsis;
          font-family: Inter;
          font-display: swap;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 29.314px; /* 146.569% */
          @media ${settingsStyled.m479} {
            font-size: 13px;
            line-height: normal;
          }
        }
      }
      /* Set the direction to column */
    }
  }
`;

export { CardContainer };
