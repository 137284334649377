import styled from "styled-components";
import settingsStyled from "../../../styled/settings.styled";

export const OurMachinesStyled = styled.section`
  .ourMachines {
    &-container {
      display: flex;
      justify-content: center;
      margin-bottom: 2.889vw;

      &-content {
        width: 70%;
        margin: auto;
        text-align: center;

        @media ${settingsStyled.m767} {
          width: 100%;
          margin-bottom: 2.889vw;
          padding: 0 32px;
        }
      }
    }
  }

  .slick {
    &-slide {
      @media ${settingsStyled.m767} {
        opacity: 0.5;
      }
    }

    &-active {
      opacity: 1;
    }
  }

  .ant-tabs-nav-list{
    padding-left: 32px;
  }
`;
