import styled, { keyframes } from "styled-components";
import settingsStyled from "../../../styled/settings.styled";

const bouncing = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-3px);
  }
  60% {
    transform: translateX(-1px);
  }
`;

export const ProductCardStyled = styled.article`
  width: 422.8px;
  height: 549px;
  margin-right: 40px;
  box-shadow: 0 3.2px 8.3px 1.6px rgba(103, 103, 103, 0.1);

  @media ${settingsStyled.m959} {
    width: 346px;
    height: 471px;
    margin-right: 24px;
    border-radius: 8px;
  }

  @media ${settingsStyled.m479} {
    width: 276px;
    height: 311px;
    margin-right: 24px;
    border-radius: 8px;
  }

  .productCard {
    &-content {
      height: 50%;
      position: relative;
      width: 100%;
      padding: 20px;

      @media ${settingsStyled.m959} {
        height: calc(100% - 193px);
        padding: 16px;
      }

      @media ${settingsStyled.m479} {
        height: calc(100% - 153px);
        padding: 12px;
      }

      &-button {
        position: absolute;
        bottom: 20px;
        button {
          display: flex;
          flex-direction: row;
          border-color: transparent;
          align-items: center;
          .formSubmitButton--iconRight {
            margin-left: 0px;
          }
          padding-left: 0px;
          & > div > svg {
            fill: #365097;
            width: 26px;
            height: 26px;
          }

          &:hover {
            & > div > svg {
              animation: ${bouncing} 1s infinite;
            }
          }
        }
        span {
          color: var(--Primary-Blue, #1c3b84);
          font-family: Inter;
          font-display: swap;
          font-size: 16px;
          font-style: normal;
          font-weight: 600; /* 32.005px */
          text-align: center;
        }
      }
      &-title {
        color: var(--bw-black, #343434);
        font-size: 22px;
        font-weight: 600;
        line-height: 1.4;
        min-height: 54px;
        margin-bottom: 25px;

        @media ${settingsStyled.m959} {
          font-size: 19px;
        }

        @media ${settingsStyled.m479} {
          font-size: 16px;
          line-height: normal;
          margin-bottom: 10px;
          min-height: auto;
        }
      }
      &-description {
        color: #5d7285;
        font-size: 18px;
        font-weight: 400;
        line-height: 29.314px;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        @media ${settingsStyled.m959} {
          font-size: 16px;
        }

        @media ${settingsStyled.m479} {
          font-size: 13px;
          line-height: normal;
          -webkit-line-clamp: 2;
        }
      }
    }
  }

  .ant-image {
    box-shadow: 0 3.2px 8.3px 1.6px rgba(103, 103, 103, 0.1);
    width: 100%;
    height: 262px;

    @media ${settingsStyled.m959} {
      height: 193px;
    }

    @media ${settingsStyled.m479} {
      height: 153px;
    }

    img {
      height: 100%;
      width: 100%;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  }

  .ant-card {
    border-radius: 8px;
  }
`;
