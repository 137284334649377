import { Card, Image } from "antd";
import { memo, useCallback, useEffect, useState } from "react";
import { imagesReplace } from "../../shared/utils/mixedControl";
import { useRouter } from "next/router";
import ButtonCustom from "../../shared/partnerComponents/button/buttonCustom";
import { ProductCardStyled } from "./styled/productCard.styled";

const ProductCard = ({ product, index }) => {
  // console.log(product);
  const { push } = useRouter();
  const [img, setImg] = useState(null);
  useEffect(() => {
    setImg(imagesReplace(product?.imagePath));
  }, [product]);

  const handleProductCategory = useCallback(
    (item) => {
      //console.log(item?.productCategory?.slugURL);
      if (item) {
        push(`/urunler/${item?.productCategory?.slugURL}`);
        const section = document.getElementById("navbar");
        if (section) {
          section.scrollIntoView();
        }
      }
    },
    [product]
  );

  //console.log(img);
  if(!product) return <div className="placeholder"></div>
  return (
    <ProductCardStyled className="productCard">
      <Card
        key={index}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        
        <Image
          src={img === null ? "/images/defaultImage.png" : img}
          preview={false}
           width="600" height="400"
        />
        <div className="productCard-content">
          <p className="productCard-content-title">{product.name}</p>
          <p className="productCard-content-description">
            {product.shortDescription
              ? product.shortDescription
              : "Detaylar için Keşfedin..."}
          </p>
          <ButtonCustom
            onClick={() => handleProductCategory(product)}
            label="Keşfet"
            iconName="icon-chevron-right"
            iconPosition="right"
            className="productCard-content-button"
            backgroundColor="transparent"
          />
        </div>
      </Card>
    </ProductCardStyled>
  );
};
export default memo(ProductCard);
