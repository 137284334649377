import { memo } from "react";
import { selectHomeDataList } from "../../store/private/homePageSlice";
import { useSelector } from "react-redux";
import { imagesReplace } from "../../shared/utils/mixedControl";
import { HomeImagesStyled } from "./styled/homeImages.styled";
import Image from "next/image";

const HomeImages = ({headlines}) => {
  if (!headlines) return null;
  return (
    <HomeImagesStyled className="homeImages">
      <div className="homeImages-image-container">
        <div className="homeImages-image-container-text">
          <p>{headlines?.[0]?.title}</p>
        </div>
        {/* imagesReplace(headlines?.[0]?.imagePath) */}
        <img
          src={imagesReplace(headlines?.[0]?.imagePath)}
          alt="Image 1"
          className="homeImages-image-container-fullWidth"
          width={100} // resmin genişliği
          height={660} // resmin yüksekliği
          priority="true" 
          quality={100}
        />
        <Image
          src="/images/image_katman.png"
          alt="PNG Katman 2"
          className="homeImages-image-container-pngKatman"
          width={100} // resmin genişliği
          height={660} // resmin yüksekliği
          priority="true" 
        />
        <Image
          src="/images/home/home1.png"
          alt="Image 2"
          className="homeImages-image-container-right-aligned"
          width={710} // resmin genişliği
          height={100} // resmin yüksekliği
          priority="true" 
          loading="eager"
        />
      </div>
    </HomeImagesStyled>
  );
};

export default memo(HomeImages);
