import wrapper from "../store";
import dynamic from "next/dynamic";
import { getHomeData } from "../store/private/homePageSlice";
import { getAnnouncementsPageData } from "../store/private/announcementsPageSlice";
import { useState } from "react";
import Auth from "../shared/auth";
import HomeImages from "../components/Home/homeImages";
import HomeTabCard from "../components/Home/homeTabCard";
import FileCarousel from "../components/fileCarousel"
import Loading from "../shared/partnerComponents/loading/loading";
import { getNavbarData } from "../store/private/headerNavbarSlice";
const ModalGeneral = dynamic(() => import('../shared/partnerComponents/modal/modalGeneral'));
const ModalData = dynamic(() => import('../components/modalData'));
const HeadCustom = dynamic(() => import('../shared/partnerComponents/headCustom'));
const SolutionCategories = dynamic(() => import('../components/Home/solutionCategories'));
const ComplateLineSolutions = dynamic(() => import('../components/Home/complateLineSolutions'));
const WorldMap = dynamic(() => import('../components/Home/worldMap'));
const SubscribeUS = dynamic(() => import('../components/fallowUs'));

export const getServerSideProps = wrapper.getServerSideProps((store) => async (ctx) => {
  const { dispatch, getState } = store;
  await Auth({ ctx, store });
    let item = await getState().homePage;
    let announcementsItem = await getState().announcements;
    let navbarData = await getState().navbar;


    if (item?.loading) {
      await dispatch(getHomeData(ctx)); 
      await dispatch(getNavbarData(ctx)); 
      await dispatch(getAnnouncementsPageData(ctx));
      item = await getState().homePage;
      navbarData = await getState().navbar;
      announcementsItem = await getState().announcements;
    }


    return {
      props: {
        data: item?.data || {},
        announcements: announcementsItem.item || {}
      },
    };
});

const Home = ({ data, announcements }) => {
  const [showModal, setShowModal] = useState(false);
  const {
    countries, 
    documents, 
    headlines, 
    productCategories, 
    products, 
    seoTag, 
    solutionCategories , 
    widgets
  } = data || null;

  return !data || !announcements ? (
    <Loading innerPageActive/>
  ) : (
    <>
      <HeadCustom metatags={seoTag && seoTag?.metaTags} title={seoTag?.title || "Ersey"} description={seoTag?.description || "Ersey Kurumsal"} />
      <HomeImages headlines={headlines && headlines} /> 
      <HomeTabCard productCategories={products && productCategories} products={products && products} widgets={widgets && widgets}  />  
      <SolutionCategories solutionCategories={solutionCategories && solutionCategories} widgets={widgets && widgets} />
      <ComplateLineSolutions widgets={widgets && widgets} />
      <FileCarousel documents={documents ? documents : null} widgets={widgets && widgets} />
      <WorldMap widgets={widgets &&  widgets} countries={countries && countries} />
      <SubscribeUS title={widgets && widgets[5]?.title} subTitle={widgets && widgets[5]?.subTitle} />
       <ModalGeneral modalStatus={showModal} modalCloseControl={() => setShowModal(false)} closeIconActive={true} whiteModalActive={true} sideClosing={true} closePositonLeft={false} className="announcements">
        <ModalData modalData={announcements && announcements} />
      </ModalGeneral>
    </>
  );
};
export default Home;
